<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2022-08-05 16:20:49
-->
<template>
	<div class="login">
		<div class="login_form animate__animated animate__zoomInDown">
			<div class="login_form_title">
				<span class="form_title_welcome">欢迎登录 ~</span>
				<span class="form_title_name">浙江工业大学志愿服务系统</span>
			</div>
			<div class="login_form_content">
				<el-form ref="loginForm" :model="loginForm">
					<el-form-item label="">
						<el-input class="login_form_ipt" placeholder="请输入账号" v-model="loginForm.username"></el-input>
					</el-form-item>
					<el-form-item label="">
						<div class="login_form_pwd">
							<el-input :type="showPwd?'text':'password'" class="login_form_ipt" placeholder="请输入密码" v-model="loginForm.password"></el-input>
							<img @click="showPwdFn" v-if="!showPwd"
								src="@/assets/images/common/eye_close.png">
							<img @click="showPwdFn" v-else src="@/assets/images/common/eye_open.png">
						</div>
					</el-form-item>
				</el-form>
				<div v-preventReClick @click="login" class="login_form_btn">登录</div>
				<div class="login_form_remember">
					<el-checkbox @change="remeberMe" v-model="loginForm.remeber">记住账号</el-checkbox>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		mapActions
	} from 'vuex';
	import formatFn from "@/utils/formatFn.js";
	import storage from 'store';
	export default {
		data() {
			return {
				loginForm: {
					remeber: false,
					username: undefined,
					password: undefined,
					authType: "0",
				},
				showPwd:  false,
			}
		},
		mounted() {
			if (storage.get('REMEMBER_ME')) {
				this.loginForm.remeber = storage.get('REMEMBER_ME')
				if (this.loginForm.remeber) {
					this.loginForm.username = storage.get('REMEMBER_NAME')
				}
			}
		},
		methods: {
			...mapActions(['Login', 'GetUserInfo']),
			showPwdFn() {
				this.showPwd = !this.showPwd
			},
			remeberMe() {
				if (this.loginForm.remeber) {
					if (this.loginForm.username) {
						storage.set('REMEMBER_NAME', this.loginForm.username, 7 * 24 * 60 * 60 * 1000)
					}
				} else {
					if (storage.get('REMEMBER_NAME')) {
						storage.remove('REMEMBER_NAME')
					}
				}
				storage.set('REMEMBER_ME', this.loginForm.remeber, 7 * 24 * 60 * 60 * 1000)
			},
			login() {
				const {
					username,
					password
				} = this.loginForm
				if (!username) {
					this.$message.error('请输入账号~');
					return
				} else if (!password) {
					this.$message.error('请输入密码~');
					return
				}
				this.Login(this.loginForm).then(async (res) => {
					if (res.code == 0) {
						await this.GetUserInfo()
						this.$router.push({
							path: '/recruitList'
						})
						this.remeberMe()
						setTimeout(() => {
							this.$notify({
								dangerouslyUseHTMLString: true,
								message: `<strong>尊敬的<span style="color:#5BA897;margin:0 4px;">${username}</span>用户${formatFn.timeFormat()}, 欢迎来到浙江工业大学志愿服务系统~</strong>`,
								duration: 4000
							});
						}, 1000);
					}
				})
			}
		},
	}
</script>
<style lang="scss" scoped>
	/deep/.el-checkbox__label {
		color: #ffffff;
	}

	.login {
		width: 100%;
		height: 100vh;
		background-image: url("../../assets/images/login/back_bg.png");
		background-size: 100% 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		.login_form {
			width: 400px;
			height: 400px;
			background-image: url("../../assets/images/login/form_bg.png");
			background-size: 100% 100vh;
			padding: 43px 40px 55px 40px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;

			.login_form_title {
				display: flex;
				flex-direction: column;

				.form_title_welcome {
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 22px;
					color: #BDC6D0;
				}

				.form_title_name {
					font-size: 20px;
					font-family: PingFang SC;
					font-weight: 600;
					line-height: 28px;
					color: #FFFFFF;
					margin: 10px 0 32px 0;
				}
			}

			.login_form_content {
				.login_form_pwd {
					width: 100%;
					display: flex;
					align-items: center;
					position: relative;
					img {
						width: 16px;
						height: 16px;
						position: absolute;
						right: 10px;
						cursor: pointer;
					}
				}
				.login_form_ipt {}

				.login_form_btn {
					width: 320px;
					line-height: 40px;
					background: $theme_color;
					border-radius: 4px;
					text-align: center;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #FFFFFF;
					margin-top: 40px;
					cursor: pointer;
				}

				.login_form_remember {
					margin-top: 20px;
				}
			}
		}
	}
</style>
